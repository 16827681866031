@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100vh;
  min-height: 100svh;
  background: #030016;
  color: #fff;
  font-family: "Satoshi";
}

.min-h-main {
  min-height: calc(100vh - 7rem * 2);
}

.main {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
}

.border-gradient {
  position: relative;
}

.border-gradient::after {
  content: "";
  position: absolute;
  inset: 0;
  border: 1px solid white;
  border-radius: inherit;
  mask: linear-gradient(
    186.06deg,
    #ffffff -164.7%,
    rgba(255, 255, 255, 0) 164.57%
  );
}

.toast-position {
  top: 6rem !important;
}

.notification {
  position: relative;
  padding: 16px 15px 16px 22px;
  background-color: rgba(255, 255, 255, 0.1);
  /*background-color: black;*/
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.notification-left {
  display: flex;
}

.notification .text-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}

.notification-img {
  cursor: pointer;
}

.notification-border {
  height: 100%;
  position: absolute;
  left: 1px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.notification .notification-border {
  border-left: 1px solid #8fffa8;
}

.notification._error .notification-border,
.notification._info .notification-border {
  border-left: 1px solid rgba(255, 158, 182, 1);
}

@keyframes slidein {
  from {
    opacity: 0;
    scale: 0;
  }

  to {
    opacity: 1;
    scale: 1;
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    scale: 1;
  }

  to {
    opacity: 0;
    scale: 0;
  }
}

.noHeight {
  height: 0px;
  overflow: hidden;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.input-number::-webkit-outer-spin-button,
.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-number[type="number"] {
  -moz-appearance: textfield;
}

.text-gradient {
  background: linear-gradient(90deg, #e5c4ff 0%, #9d8dff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dapp-page--grid-item-bg {
  --mask-height: 54px;
  --mask-width: 104px;
  --bg-color: rgba(255, 255, 255, 0.05);
  position: absolute;
  inset: 0;
  border-radius: 16px;
  overflow: hidden;
  pointer-events: none;
}

.dapp-page--grid-item-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: var(--mask-width);
  bottom: 0;
  border-top-right-radius: 16px;
  background: var(--bg-color);
  pointer-events: none;
  z-index: -1;
}

.dapp-page--grid-item-bg::after {
  content: "";
  position: absolute;
  top: var(--mask-height);
  left: calc(100% - var(--mask-width));
  right: 0;
  bottom: 0;
  border-top-right-radius: 16px;
  background: var(--bg-color);
  pointer-events: none;
  z-index: -1;
}

.dapp-page--grid-item-bg_1 {
  position: absolute;
  top: 0;
  right: 0;
  width: var(--mask-width);
  height: var(--mask-height);
  overflow: hidden;
}

.dapp-page--grid-item-bg_1::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: var(--mask-width);
  height: var(--mask-height);
  border-bottom-left-radius: 16px;
  box-shadow: 0px 0px 0px 10px var(--bg-color);
  pointer-events: none;
  z-index: -1;
}

.dapp-page--grid-item--link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
}

.dapp-page--grid-item--link img {
  width: 16px;
  height: 16px;
}

.dapp-page--grid-item--action {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 42px;
  width: 42px;
  min-height: 42px;
  height: 42px;
  background: linear-gradient(158.55deg, #b888e9 3.02%, #8f7cff 85.9%);
  border-radius: 8px;
  box-shadow: 0px 0px 0px 0px #ffffff30, 0px 0px 0px 0px #ffffffcf inset,
    0px 0px 7.5px 0px #ffffffc7 inset !important;
  color: #fff;
  text-decoration: none !important;
  transition: all 0.3s ease-in-out;
}

.dapp-page--grid-item--action-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 42px;
  width: 42px;
  min-height: 42px;
  height: 42px;
  background: #ffffff1a;
  border-radius: 8px;
  color: #fff;
  text-decoration: none !important;
  transition: all 0.3s ease-in-out;
}

.dapp-page--grid-item--action:hover {
  box-shadow: 0px 0px 20px 4px #ffffff30, 0px -1px 6px 0px #ffffffcf inset,
    0px 0px 7.5px 0px #ffffffc7 inset !important;
}

.bg-gradient-to-t {
  background: linear-gradient(to top, black, transparent);
}

.custom-scrollbar::-webkit-scrollbar {
  background-color: transparent;
  width: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #b888e9;
  border-radius: 2px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 0;
  height: 0;
  background: transparent;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  appearance: none;
  width: 0;
  height: 0;
  background: transparent;
  cursor: pointer;
}

input[type="range"]::-ms-thumb {
  appearance: none;
  width: 0;
  height: 0;
  background: transparent;
  cursor: pointer;
}
