.defaultRadius {
  border-radius: 20px !important;
}

.gradient-border-mask-connect-wallet {
  position: relative !important;
}

.gradient-border-mask-connect-wallet::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px !important;
  border: 1px solid transparent;
  background: linear-gradient(359deg, transparent, rgba(255, 255, 255, 0.6)) border-box;
  mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.connectModalModalBody {
  width: 492px !important;

  padding: 0px;

  margin-inline: auto;
}

@media (max-width: 500px) {
  .connectModalModalBody {
    width: 100vw !important;
  }
}

.connectModalIconContainer {
  margin-right: 12px;
  border-radius: 15px;
}

.connectModalTitle {
  margin-bottom: 40px;
  color: white;
  font-family: Satoshi;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.connectFormContainer {
  /* margin-top: 266px; */
  padding: 59px 43px 65px 43px;
  position: relative;
  z-index: 100;
  width: 492px;
  min-height: 335px;
  background: linear-gradient(180deg, #5a43b4 -27.02%, #0d0821 109.79%),
    linear-gradient(180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%);
}

.connectFormContainer.small {
  min-height: 0 !important;
  padding: 59px 43px 31px 43px;
}

@media (max-width: 500px) {
  .connectFormContainer {
    width: 100%;
  }
}

.connectModalCloseBtn {
  top: 29.75px;
  right: 30.75px;
  position: absolute;
}

.activeWalletsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 0px;
}

.activeWalletsGridItem0 {
  grid-area: 1 / 1 / 2 / 3;
}

.activeWalletsGridItem1 {
  grid-area: 2 / 1 / 3 / 2;
}

.activeWalletsGridItem2 {
  grid-area: 2 / 2 / 3 / 3;
}

.activeWalletsGridItem3 {
  grid-area: 3 / 1 / 4 / 2;
}

.activeWalletsGridItem4 {
  grid-area: 3 / 2 / 4 / 3;
}

.activeWalletsGrid .disabledSoon {
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.05);

  -webkit-user-select: none;
  user-select: none;
}

.activeWalletsGrid .disabledSoon span {
  color: rgba(255, 255, 255, 0.4);
}

.connectModalItem {
  z-index: 100;

  display: flex;
  align-items: center;

  width: 100%;
  height: 60px;
  padding: 12px 12px 12px 16px;
  border-radius: 12px;
  background: #ffffff1a;
  margin-bottom: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.connectModalItem:hover {
  background-color: #ffffff40;
}

.connectNetworkSpan {
  color: white;
  letter-spacing: 0em;
  font-family: Satoshi;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}