.walletDisconnectModalTopText {
  font-family: Satoshi, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #a39dc7;
}

.walletDisconnectModal__account-wrapper {
  justify-content: space-between;
}

@media (max-width: 610px) {
  .footer-faq {
    display: none !important;
  }
}

@media (max-width: 480px) {

  .walletDisconnectModalTopText,
  .walletDisconnectModalBottomText span {
    /* padding: 0 !important;
    margin: 0 !important; */

    /* text-wrap: nowrap; */
    white-space: nowrap;
  }

  .walletDisconnectModalTopText,
  .walletDisconnectModalBottomText {
    padding: 0 !important;
    margin: 0 !important;

    white-space: nowrap;
  }
}

.walletDisconnectModalBottomText {
  font-weight: 700;
  color: white;
}

.walletDisconnectModalDivider {
  background-color: #b5b2c938;
  height: 1px;
  margin: 14px 0;
}

.walletDisconnectModalDangerText {
  font-family: Satoshi, sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #ff9eb6;
}

.powerIconSize {
  width: 14px;
  height: 14px;
  margin-right: 13px;
}

.power-icon-display {
  display: block;
}

.power-icon-white-display {
  display: none;
}

.walletDisconnectModalDangerDiv {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding-right: 20px;
  padding-left: 20px;
  padding-block: 5px;
  cursor: pointer;
}

.walletDisconnectModal {
  width: 200px;
  height: 130px;
  padding: 20px 0;
  border-radius: 12px;
  border: 0.5px solid #ffffff80;
  gap: 14px;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
  display: block;
  transition: all 0.5s ease;
  z-index: 100;
  backdrop-filter: blur(25px);
}

.walletConnectModal {
  width: 200px;
  min-width: 200px;
  height: 110px;
  min-height: 110px;
  padding: 20px 16px 20px 16px;
  border-radius: 12px;
  border: 0.5px solid #ffffff80;
  gap: 14px;
  position: absolute;
  top: 110%;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s ease;
  z-index: 100;
}

.walletConnectModalText {
  font-family: Satoshi-Bold;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: start;
  color: white;
}

.walletDisconnectModalImg {
  width: 28px;
  height: 28px;
  /* margin-right:12px ; */
  border-radius: 40px;
  background-color: white;
}

.walletDisconnectModalHide {
  width: 100%;
  height: 130px;
  padding-top: 20px;
  padding-left: 16px;
  border-radius: 12px;
  border: 0.5px solid #ffffff80;
  gap: 14px;
  position: absolute;
  top: 30px;
  left: 0;
  opacity: 0;
  background-color: #ffffff20;
  transition: all 0.5s ease;
  display: none;
}