.btn {
  color: white;
  width: 171px;
  height: 50px;
  padding: 12px 18px 12px 18px;
  border-radius: 80px;

  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  box-shadow: inset white 0px 0px 5px;
  background: radial-gradient(circle,
      rgba(123, 73, 244, 1) 34%,
      rgba(143, 124, 255, 1) 64%,
      rgba(227, 196, 255, 1) 100%) !important;
  transition: opacity 0.3s ease;
}

.btn:hover {
  color: #ffffff !important;
  box-shadow: 0px 0px 30px 4px #ffffff72, 0px -1px 6px 0px #ffffffcf inset,
    0px 2px 4px 0px #ffeec159 inset !important;
}

.btn:disabled {
  background: rgba(255, 255, 255, 0.1) !important;
  border-color: transparent !important;
  color: rgba(255, 255, 255, 0.4) !important;
  box-shadow: none !important;
  pointer-events: none;
}