.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -90px 0 0 -90px;
}

.loader .circle {
  position: absolute;
  border: 3px solid transparent;
  border-top-color: hsl(252, 84%, 73%);
  border-radius: 50%;
  background-color: transparent;
}

.loader .circle.one {
  height: 50px;
  width: 50px;
  left: 50px;
  top: 50px;
  transform: rotateZ(0deg);
  animation: rotate1 linear infinite;
  animation-duration: 0.85s;
}

.loader .circle.two {
  height: 75px;
  width: 75px;
  top: 38px;
  left: 38px;
  transform: rotateZ(140deg);
  animation: rotate2 linear infinite;
  animation-duration: 0.95s;
}

.loader .circle.three {
  height: 100px;
  width: 100px;
  top: 25px;
  left: 25px;
  transform: rotateZ(280deg);
  animation: rotate3 linear infinite;
  animation-duration: 1.05s;
}

@keyframes rotate1 {
  from {
    transform: rotateZ(360deg);
  }

  to {
    transform: rotateZ(0deg);
  }
}

@keyframes rotate2 {
  from {
    transform: rotateZ(500deg);
  }

  to {
    transform: rotateZ(140deg);
  }
}

@keyframes rotate3 {
  from {
    transform: rotateZ(640deg);
  }

  to {
    transform: rotateZ(280deg);
  }
}