.header-nav-item {
  position: relative;
  height: 21px;
}

@media screen and (min-width: 768px) {
  .header-nav-item {
    height: auto;
  }
}

.header-nav-item::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(8px);
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 100px;
  display: block;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}

.header-nav-item.active::after {
  opacity: 1;
}
